.partner-section {
  background: $gray15;
  overflow: hidden;
  @include container-up {
    //padding: 106px 0 36px;
  }

  .wrapper {
    .title-wrap {
      max-width: 800px;
      margin: 0 auto 20px;
      text-align: center;
      h2 {
        color: $light-bluewhile;
        font-weight: 300;
      }
      @include tablet-down {
        margin-bottom: 30px;
        h2 {
          font-size: 20px;
        }
      }
    }
    .full-row {
      margin-right: 0;
      margin-left: -11px;
      height: 100%;
      padding: 10px 0 26px;
      .col-wrap {
        max-width: 25%;
        padding: 0 0 0 10px;
        @include tablet-down{
         flex: none;
         max-width: 50%;
         width: 100%;
        }
        @include phone-down {
          max-width: 100%;
        }
        .stories-box {
          margin: 0 auto 20px;
          max-width: 380px;
          text-align: center;
          position: relative;
          @include phone-down {
            max-width: 100%;
          }
          &.show {
            span {
              &:after {
                // transform: rotate(-136deg);
                // top: 58%;
              }
            }
            .success-stories {
              opacity: 1;
              border: 1px solid $white3;
              visibility: visible;
            }
          }
          span {
            position: relative;
            max-width: 380px;
            background: white;
            padding: 14px 20px;
            display: block;
            color: $light-bluewhile;
            font-size: 15px;
            font-weight: 300;
            margin: 0 auto;
            text-align: left;
            border: 1px solid $white3;
            &:before {
              content: '';
              position: absolute;
              text-align: center;
              background: $white4;
              width: 20px;
              height: 20px;
              border-radius: 50%;
              top: 50%;
              right: 10px;
              margin: -10px 0 0;
            }
            &:after {
              content: '';
              position: absolute;
              top: 50%;
              margin: -6px 0 0 -4px;
              right: 16px;
              width: 8px;
              height: 8px;
              border: solid $black4;
              border-width: 0 1px 1px 0;
              transform: rotate(45deg);
            }

            @include phone-down {
              max-width: 100%;

            }

          }
          .success-stories {
            border: 1px solid $white3;
            list-style: none;
            text-align: left;
            background: white;
            position: absolute;
            z-index: 1;
            width: 100%;
            opacity: 0;
            min-height: 174px;
            max-height: 340px;
            // height: 414px;
            overflow: scroll;
            visibility: hidden;
            padding-left: 0;
            li {
              font-size: 18px;
              font-weight: 100;
              color: #656565;
              padding: 7px 20px;
              margin: 0;
              &:hover {
                background: $white7;
                color: $light-bluewhile;
              }
            }

          }
        }
        &.col-search {
          display: flex;
          max-width: 100%;
          align-items: center;
          width: 100%;

          @include desktop-down {
            padding: 10px;
            max-width: 100%;
            width: 100%;
          }
          @include tablet-down {
            display: block;
          }
          @include phone-down {
            padding: 10px;
          }

          .input-box {
            width: 100%;
            position: relative;
            display: flex;
            max-width: 100%;
            @include container-down {
              padding-left: 0;
              max-width: 100%;
            }
            @include desktop-down {
              width: 100%;
              max-width: 100%;
              // margin-left: 0;
            }
            @include tablet-down {
              padding-left: 0;
              width: 100%;
              margin: 0 auto;
            }
            @include phone-down {
              max-width: 100%;
            }

            input {
              margin-right: 10px;
              font-size: 15px;
              padding: 10px 12px;
              color: $light-bluewhile;
              border: 2px solid $white3;
              height: 40px;
              font-family: $font-families;
              background-color: $white;
              font-weight: 400;
              letter-spacing: 1.4px;
              outline: none;
              max-width: 914px;
              width: 100%;
              box-sizing: border-box;
              appearance: none;
              border-radius: 0;
              @include phone-down {
                max-width: 260px;
                margin-right: 3px;

              }
            }
            input::placeholder {
              font-family: $font-families;
              font-size: 15px;
              font-weight: 300;
              line-height: 1.21;
              letter-spacing: normal;
              color: $white3;
            }
            button {
              border: none;
              outline: none;
              display: inline-block;
              vertical-align: middle;
              background: -webkit-gradient(linear, left top, left bottom, from(#4eb2ca), to(#1f6ea9));
              background: -webkit-linear-gradient(top, #4eb2ca 0%, #1f6ea9 100%);
              background: linear-gradient(to bottom, #4eb2ca 0%, #1f6ea9 100%);
              width: 100%;
              text-decoration: none;
              position: relative;
              z-index: 0;
              text-align: center;
              margin: 0;
              border-radius: 0;
              height: 40px;
              padding: 0px 0;
              max-width: 146px;
              border: 0;
              cursor: pointer;
              color: $white;
              font-size: 18px;
              line-height: 1.38;
              letter-spacing: normal;
              font-family: $font-families;
              overflow: hidden;
              @include phone-down {
                max-width: 75px;
              }
              span {
                font-family: $font-families;
                position: relative;
                font-size: 16px;
                font-weight: 300;
              }

            }
          }
        }
      }
    }
    .certbadge {
      height: 70px;
      width: 160px;
      background-repeat: no-repeat;
      background-size: contain;
    }
    .certbadge span {
      font-family: "canada-type-gibson", sans-serif !important;
      font-size: 18px;
      color: #FFFFFF;
      line-height: 36px;
      width: 35px;
      display: block;
      text-align: center;
      font-weight: 600;
    }
    .badge-sm {
      width: 100px !important;
      margin: 10px 3px;
    }
    .badge-lg {
      max-width: 160px;
      margin: 30px 10px 1rem;
    }
    .content-wrap {
      background: $white;
      width: 100%;
      border-bottom: 2px solid $blue9;
      display: flex;
      padding: 30px 48px 0 36px;
      position: relative;
      margin: 0 0 31px;
      @include desktop-down{
        padding: 20px 20px 0;
      }
      @include tablet-down {
      display: block;
      text-align: center;
      padding: 20px;
      }
      .col-wrap {
        width: 100%;
        display: inline-block;
        @include tablet-down {
          display: block;
          flex: none;
          max-width: 100%;
          width: 100%;
        }
        @include phone-down {
          width: 100%;
        }
        &.img-wrap {
          height: auto;
          width: 30%;
          max-width: 250px;
          align-self: flex-start;
          padding: 16px 23px 10px 0;
          text-align: center;
          @include desktop-down{
            padding: 0;
            position: relative;
            width: 100%;
            height: 100%;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            margin: auto;

          }
          @include tablet-down {
            padding: 0;
            max-width: 100%;
            width: 100%;
          }
          img {
            object-fit: cover;
            width: 100%;
            height: auto;
          }
        }
        &.content {
          flex-grow: 1;
          padding: 16px 20px 14px 68px;
          // padding: 15px 20px 14px 34px;
          width: 60%;
          @include desktop-down{
            padding: 15px;
          }
          @include tablet-down {
            padding: 0;
            width: 100%;
          }
          h2 {
            color: $light-bluewhile;
            font-weight: 300;
            padding-bottom: 20px;
            font-size: 22px;
            @include tablet-down {
              font-size: 20px;
            }
          }
          img {
            width: 70px;
            height: 24px;
            height: auto;
            margin-left: 10px;
          }
        }
        p {
          // font-size: 22px;
          color: $light-bluewhile;
          a {
            font-size: 22px;
          }
        }
        &.link-cell {
          flex-grow: 1;
          align-self: flex-end;
          width: 10%;
          padding: 0px 0 54px 74px;
          @include desktop-down{
            width: 20%;
            padding: 20px 20px 20px 0;
          }
          @include  tablet-down{
            width: 100%;
            padding: 0;
          }
          .active-link {
            white-space: nowrap;
            color: #000;
            font-size: 18px;
            font-weight: 100;
            text-decoration: none;
            position: relative;
            display: inline-block;
            vertical-align: top;
          }
        }

      }
    }
  }

  .pagination,
  .pager {
    display: flex;
    margin-top: 50px;
    margin-bottom: 10px;
    @include tablet-down {
      justify-content: center;
      margin-top: 30px;
      ul {
        display: flex;
        list-style: none;
        @include tablet-down {
          margin: 0 10px;
        }
        li {
          margin: 0 35px 0 0;
          color: $sky-light-blue;
          @include tablet-down {
            margin: 0 10px 0 0;
          }
          &:nth-last-child(1) {
            margin-right: 0;
          }
          a {
            color: $sky-light-blue;
            font-size: 18px;
            font-weight: 300;
            &:hover {
              color: $black;
              text-decoration: none;
            }
            &.active {
              color: $black;
            }
          }
        }
      }
    }
  }
  .views-exposed-form {
    display: none;
  }

  .col-wrap.col-badge {
    display: inline-grid;
  }

  .partner-custom-badges img {
    margin-bottom: 20px;
  }
  h1.partner-title {
    font-weight: 500;
    font-size: 30px;
    padding-bottom: 20px;
    line-height: 1.06;
  }
}


